import { createAsync } from '@solidjs/router';
import { Title } from '@solidjs/meta';
import {
	addToast,
	Button,
	CheckList,
	CheckListItem,
	Dialog,
	DialogAction,
	DialogActions,
	DialogContent,
	DialogTrigger,
	Errors,
	Form,
	Heading,
	HorizontalRule,
	Link,
	LinkButton,
	MessageBar,
	Section,
	Tag,
} from '@troon/ui';
import { createSignal, For, Match, Show, Suspense, Switch } from 'solid-js';
import dayjs from '@troon/dayjs';
import { IconArrowRightMd } from '@troon/icons/arrow-right-md';
import { IconCalendar } from '@troon/icons/calendar';
import { gql, mutationAction, TroonCardSubscriptionStatus, useMutation } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { dayTimeToDate } from '../../modules/date-formatting';
import { getAccessProducts } from '../../providers/card';
import { usePersisted } from '../../providers/persistence-store';

export default function Access() {
	const data = createAsync(() => getSubscription({}), { deferStream: true });
	const [persisted] = usePersisted();
	const products = createAsync(() => getAccessProducts({ promoCode: persisted.promoCode?.value }), {
		deferStream: true,
	});
	const [cancelOpen, setCancelOpen] = createSignal(false);
	const cancelSubscriptionAction = useMutation(cancelMutation);

	return (
		<div class="flex flex-col gap-8">
			<Title>Troon Access Plans | My account | Troon</Title>
			<Heading as="h1">Troon Access Plans</Heading>

			<Section appearance="contained">
				<Tag as="h2">Current plan</Tag>
				<Suspense>
					<Show when={data()?.subscription}>
						{(sub) => (
							<>
								<div class="flex flex-wrap items-center gap-y-2">
									<p class="grow text-lg font-semibold">{sub().name}</p>
									<div class="flex gap-x-4">
										<Show when={sub().updateSubscriptionUrl}>
											{(url) => (
												<Button as={Link} href={url()} class="size-fit grow-0">
													Upgrade
												</Button>
											)}
										</Show>
										<Show when={sub().manageSubscriptionUrl}>
											{(url) => (
												<Button appearance="secondary" as={Link} href={url()} class="size-fit grow-0">
													Manage Subscription
												</Button>
											)}
										</Show>
									</div>
								</div>
								<Switch>
									<Match when={sub().nextInvoiceDate}>
										<MessageBar icon={IconCalendar}>
											<Show when={sub().nextInvoiceDate}>
												{(date) => (
													<>Your plan will auto-renew on {dayjs(dayTimeToDate(date())).format('MMMM D, YYYY')}</>
												)}
											</Show>
											<Show when={sub().nextInvoiceAmount}>
												{(amount) => <> at {amount().displayValue} per year</>}
											</Show>
											.
										</MessageBar>
									</Match>
									<Match when={sub().endDayTime}>
										{(endDate) => (
											<MessageBar icon={IconCalendar} class="text-red-600">
												Your plan is set to expire on {dayjs(dayTimeToDate(endDate())).format('MMMM D, YYYY')}.
											</MessageBar>
										)}
									</Match>
								</Switch>
								<HorizontalRule />
								<p>Includes:</p>
								<Suspense>
									<Show when={products()?.products}>
										{(products) => (
											<CheckList class="grid grid-cols-1 gap-3 md:grid-cols-2">
												<For each={products().find((p) => p.subscriptionName === sub().name)?.valueProps}>
													{(value) => <CheckListItem>{value}</CheckListItem>}
												</For>
												<li>
													<LinkButton href="/access/benefits" class="size-fit justify-start">
														See all Benefits <IconArrowRightMd />
													</LinkButton>
												</li>
											</CheckList>
										)}
									</Show>
								</Suspense>

								<Show when={sub().status === TroonCardSubscriptionStatus.Active && sub().nextInvoiceDate}>
									<>
										<HorizontalRule />
										<Dialog key="cancel-access-subscription" onOpenChange={setCancelOpen} open={cancelOpen()}>
											<DialogTrigger appearance="secondary-current" class="size-fit" size="sm">
												Cancel plan
											</DialogTrigger>
											<DialogContent header={`Cancel ${sub()?.name}`} headerLevel="h2">
												<Form action={cancelSubscriptionAction} document={cancelQuery}>
													<p>
														Are you sure you want to cancel your {sub()?.name}?{' '}
														<Show when={sub().endDayTime ?? sub().nextInvoiceDate}>
															{(endDate) => (
																<>
																	If you cancel today, your benefits will be active until{' '}
																	{dayjs(dayTimeToDate(endDate())).format('MMMM D, YYYY')}
																</>
															)}
														</Show>
														.
													</p>
													<input type="hidden" name="subId" value={sub().id} />

													<Errors />
													<DialogActions>
														<DialogAction type="submit" action="danger">
															Yes, cancel
														</DialogAction>
														<DialogAction type="button" appearance="transparent" onClick={() => setCancelOpen(false)}>
															Keep {sub()?.name}
														</DialogAction>
													</DialogActions>
												</Form>
											</DialogContent>
										</Dialog>
									</>
								</Show>
							</>
						)}
					</Show>
				</Suspense>
			</Section>
		</div>
	);
}

const activityQuery = gql(`
query activeSubscription {
	subscription: activeTroonCardSubscription(includeManagementUrls: true) {
		id
		status
		name: subscriptionName
		startDayTime { day { year, month, day }, time { hour, minute } }
		endDayTime { day { year, month, day }, time { hour, minute } }
		totalAmount { displayValue, value }
		nextInvoiceAmount { displayValue, value }
		nextInvoiceDate { day { year, month, day }, time { hour, minute } }
		manageSubscriptionUrl
		updateSubscriptionUrl
		productType
	}
}`);

const getSubscription = cachedQuery(activityQuery);

const cancelQuery = gql(`mutation cancelAccess($subId: String!) {
	cancelTroonCardSubscription(troonCardSubscriptionId: $subId) {
		id
		name: subscriptionName,
		endDayTime { day { year, month, day }, time { hour, minute } }
	}
}`);

const cancelMutation = mutationAction(cancelQuery, {
	track: {
		event: 'cancelSubscription',
		transform: (data) => ({
			subscriptionId: data.get('subId') as string,
		}),
	},
	onSuccess: async (res) => {
		if (res?.cancelTroonCardSubscription.endDayTime) {
			addToast({
				title: (
					<>
						Your {res?.cancelTroonCardSubscription.name} has been cancelled and benefits will expire on{' '}
						{dayjs(dayTimeToDate(res.cancelTroonCardSubscription.endDayTime)).format('MMMM D, YYYY')}
					</>
				),
				variant: 'positive',
			});
		}
	},
	revalidates: ['activeSubscription', 'loggedInUser'],
});
